@import "../../assets/sass/theme";
@import "../../index.css";

body {
    text-align: left;
    font-size: 14pt;
}

@media screen and (max-width: 530px) {
    .titleText {
        margin-left: 0vw; 
        margin-right: 0vw; 
        
    }
    .title{
        justify-content: center;
    }
}

@media screen and (min-width: 530px) {
    .titleText {
        margin-left: 15vw; 
        display:flex;
    }
}

.title {
    width: 100vw;
}

.titleText {
    font-family: "FuturaBold";
    font-size: 24pt;
    justify-self: center;
    margin: 10px 0;
    box-shadow: inset 0 -5px 0 $light-blue, inset 0 -7px 0 $turquoise;
}

.projectsContainer {
    height: 100%;
    width: 100vw;
    background-color: $light-blue;
}

.projectGrid { 
    width: 95vw;
}

.projectImg {
    height: 15vh;
    text-align: left;
    border-radius: 7px;
}


.project {
    width: 80vw;
    max-width: 500px;
    margin: 12px;
}

.project:hover {
    animation: shadow 0.3s ease forwards;
    cursor: pointer;
}

@keyframes rightSlide {
    0%   {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(20px);
    }
}

@keyframes shadow {
    0%   {
        box-shadow: none;
        transform: scale(1.00);
    }
    100% {
        box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 3px 3px -2px rgba(0, 0, 0, 0.12);
        transform: scale(1.01);
    }
}