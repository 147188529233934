@import "../../assets/sass/theme";
@import "../../index.css";

body {
    text-align: left;
    font-size: 14pt;
}

@media screen and (max-width: 530px) {
    .landingContainer {
      margin-top: 30px;
    }
    
    .leftBody, #resumeButton, #helloAnimation {
        margin-left: 0vw; 
        margin-right: 0vw; 
        align-self: center;
    }

    #leftContainer {
        align-items: center;
    }
}

@media screen and (min-width: 530px) {
    .leftBody, #resumeButton, #helloAnimation {
        margin-left: 15vw; 
    }

    #introPic{
        margin-right: 15vw;
    }
    
    #leftContainer {
        align-items: flex-start;
    }
}

.leftBody {
    line-height: 1.2;
}

.landingContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

#helloAnimation {
    width: 25vw;
    min-width: 200px;
    margin-bottom: 20px;
}

#introPic {
    width: 25vw;
    height: 100%;
    border-radius: 500px;
    min-width: 200px;
    margin-top:3vh;
    margin-bottom:3vh;
    padding:0;
}

.halfContainer {
    width: 47vw;
    min-width: 250px;
}

.highlight {
    color: $turquoise;
  }  

.infoContainer {
    max-width: 30vw;
}

#resumeButton {
    border-radius: 40px;
    text-transform: none;
    font-size: 12pt;
    text-align: left;
    padding: 5px 20px;
    color: $turquoise;
    align-self: center;
}