@import "./index.css";
@import "./assets/sass/_theme.scss";

body {
  font-size: 14pt;
  width: 100vw;
  overflow-x: hidden;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
  font-size: 14pt;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #09d3ac;
}

.italic {
  font-style: italic;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

html {
  scroll-behavior: smooth;
}