@import "../../assets/sass/theme";

.projectDetContainer {
    padding: 15px;
    height: 100%;
    line-height: 1.2;
}

.projectTitle {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: calc(20px + 1vw);
    color: $turquoise;
    font-family: "FuturaBold";
    text-align: center;
}

.description {
    color: $dark-gray;
    text-align: center;
    margin-bottom: 5px;
}

.subtitle {
    color: $turquoise;
    font-size: 13pt;
    margin: 15px 0px 10px 0px;
    text-transform: lowercase;
    text-align: center;
}

#bannerImg {
    min-width: 250px;
    width: 50vw;
    height: 100%;
    margin: 2vw;
}

.header {
    color: $turquoise;
    margin: 30px 0 10px 0; //only applies to small screen sizes
    font-size: 18pt;
    text-align: center;
    font-family: FuturaOblique;
}

.body {
    margin: 0px 0px;
    color: $dark-gray;
    font-size: 14pt;
    text-align: left;
}

.caption {
    color: darken($light-purple, 10%);
    font-size: 12pt;
    margin-top: 10px;
    text-align: center;
}

.video {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 480px) {
    .body { 
       margin: 0 10vw;
    } 

    .header {
        margin: 20px 10vw 10px 10vw;
        text-align: left;
    }

    .video {
        width:70%;
    }
 }

 #worldclassBanner {
    box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    min-width: 250px;
    width: 50vw;
    height: 100%;
    margin: 2vw;
 }