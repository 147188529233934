@import "../../assets/sass/theme";
@import "../../index.css";

@media screen and (max-width: 530px) {
    #emoji {
        display: none;
    }

    .credit {
        margin-bottom: 15px;
    }

    .footerContainer {
        justify-content: center;
        flex-grow: 1;
        flex-wrap: wrap;
    }
}


@media screen and (min-width: 530px) {
    .footerContainer {
        justify-content: flex-end;
        flex-grow: 1;
        flex-wrap: wrap;
    }
}

body {
    font-size: 14pt;
}

.footerContainer {
    font-family: 'Roboto Condensed';
    margin: 15px 30px;
    display: flex;
    flex-direction: row;
}

a.external {
    font-weight: bold;
    text-decoration: none;
    color: $turquoise;
    margin: 0 10px;
}

a.external:active, a.external:visited {
    color: $turquoise;
}

a.external:hover {
    animation: hyperlinkExtend 0.2s ease forwards;
    cursor: pointer;
}

.credit {
   color: darken($light-purple, 10%);
   margin-left: 32px;
   margin-right: 32px;
}

#emoji {
    padding-left: 5px;
    margin-top: -2px;
}
