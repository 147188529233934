@import "../../assets/sass/theme";

body {
    color: $dark-gray;
}

.aboutContainer {
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

a.hyperlink {
    font-weight: bold;
    color: $turquoise;
    text-decoration: none;
    line-height: 0.9;
    position: relative;
}

a.hyperlink:active, a.hyperlink:visited {
    color: $turquoise;
}

a.hyperlink:hover {
    animation: hyperlinkExtend 0.1s ease forwards;
    cursor: pointer;
}

@keyframes hyperlinkExtend {
    0%   {
        color: $turquoise
    }
    100% {
        color: darken($light-blue, 20%);
    }
}

.name {
    font-family: "FuturaBold";
    font-size: 24pt;
    position: relative; 
    margin: 10px 0;
    box-shadow: inset 0 -1px 0 white, inset 0 -3px 0 $turquoise;
}

.picContainer {
    width: 25vw;
    min-width: 250px;
    margin: 2vw;
    display: flex;
    justify-content: center;
}

.bioContainer {
    width: 55vw;
    min-width: 250px;
    font-size: 14pt;
    margin: 1vw 0.5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.2;
}

#square {
    position: absolute;
    margin-top: calc(10vh + 20px);
    margin-left: calc(4vw + 20px);
    z-index:0;
    width: 18vw;
    min-width: 160px;
}

#aboutPic {
    height: 100%;
    width: 25vw;
    border-radius: 1000px;
    z-index:1;
    min-width: 200px;
    margin:3vh;
    padding:0;
}

@keyframes hoverShadow {
    0%   {
        text-shadow: none;
    }
    100% {
        text-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); 
    }
}

@keyframes outShadow {
    0%   {
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); 
        transform: scale(1.05);
    }
    100% {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        transform: scale(1);
    }
}