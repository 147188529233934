@import "../../assets/sass/theme";

.returnText {
    font-size: 12pt;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
}

.prevProj, .nextProj {
    padding: 5px 7px;
    border-radius: 5px;
    color: $turquoise;
}


.return, .home {
    padding: 5px 2px 5px 7px;
    border-radius: 5px;
    color: $turquoise;
}

.prevProj {
    text-align: left;
}

.nextProj {
    text-align: right;
}

.return:hover, .nextProj:hover, .prevProj:hover, .home:hover {
    animation: highlight 1s ease forwards;
    cursor: pointer;
}

.returnText:hover {
    // animation: highlight 1s ease forwards;
}

@keyframes highlight {
    0%   {
        color: $turquoise
    }
    100% {
        color: darken($light-blue, 20%);
    }
}

@keyframes shadow {
    0%   {
        box-shadow: none;
        transform: scale(1.00);
    }
    100% {
        box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 3px 3px -2px rgba(0, 0, 0, 0.12);
        transform: scale(1.01);
    }
}

@keyframes upSlide {
    0%   {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-5px);
    }
}

@keyframes leftSlide {
    0%   {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-10px);
    }
}

@keyframes rightSlide {
    0%   {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(10px);
    }
}