@import "../../assets/sass/theme";
@import "../../index.css";

body {
    font-size: 14pt;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Roboto Condensed';
    font-weight: bold;
    padding: 0px;
    position: fixed;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    width: 100vw;
    top:0;
    z-index: 2;
}

.leftHeader {
    margin-left: 24px;
    padding-top: 8px;
}

.rightHeader {
    display: flex;
    margin-right: 24px;
    padding: 16px 0;
}

.nav {
    text-decoration: none;
    color: $dark-gray;
    margin: 0 calc(2px + 1vw);
    
}

.nav:hover {
    animation: headerLink 0.2s ease forwards;
    cursor: pointer;
}

@keyframes headerLink {
    0%   {
        color: $dark-gray;
    }
    100% {
        color: $turquoise;
    }
}

.logo{
    width: 40px;
    height: 40px;
    top:0;
    left:0;
}

#topBtn:hover #logoGray {
    display: block;
}

#topBtn #logoGray {
    position: absolute;
    display: none;
  }

#topBtn {
    position: relative;
}

#namePic {
    height: 50px;

}